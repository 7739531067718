import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import '../App.css';
import CategoryGroup from '../components/CategoryGroup'
import Loading from '../components/Loading';
import { ContainerMain, CategoriesContainer } from '../App.style';
import { MyContext } from '../context/MyContext';
import { Helmet } from 'react-helmet-async';


function Categories() {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const context = useContext(MyContext);
    // let { category_slug } = useParams();
    // console.log(useParams())
    let category_all_slug = useParams()['*']

    useEffect(() => {
        setLoading(true);
        axios({
            method: 'get',
            url: '/api/categories/',
            params: {
                'slug':category_all_slug,
            }
        })
        .then(function (response) {
            // handle success
            // console.log(response.data);
            setCategories(response.data);
            context.setHeaderInfo(current=>({...current, 'title':'Categorie '+response.data.name,}))
            setLoading(false);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    }, [category_all_slug]);

    if (loading){return <Loading/>}

    const category_single = [];
    const category_group = [];
    categories.categories.map(cat=>{
        if (cat.n_categories == 0){
            category_single.push(<CategoryGroup key={cat.id} category={cat}/>)
        } else {
            category_group.push(<CategoryGroup key={cat.id} category={cat}/>)
        }
    })

    let breadcrumb_meta = ''
    let breadcrumb = categories
    const breadcrumbs = []
    while(breadcrumb){
        breadcrumbs.unshift(breadcrumb)
        breadcrumb = breadcrumb.parent;
    }
    let url_bread = window.location.origin + '/categorie'
    breadcrumbs.map((val, i)=>{
        val.name = val.name ? val.name : 'Categorie'
        val.slug = val.slug ? val.slug : ''
        url_bread += '/' + val.slug 
        breadcrumb_meta += `
        {
            "@type": "ListItem",
            "position": ${i+1},
            "name": "${val.name}",
            "item": "${url_bread}"
        }
        `
        if (i < breadcrumbs.length - 1){
            breadcrumb_meta += ','
        }
    })

    let meta_title = ''
    let meta_description = ''
    if (!categories.name){
        meta_title = 'Tutte le Categorie Prodotti - ClassificaProdottiMigliori.it'
        meta_description = 'Cerca tra migliaia di categorie il prodotto che fa al caso tuo! Con pochi click trovi tutto quello che stai cercando, selezionato per te dalla community del web'
    } else {
        meta_title = `Cerchi ${categories.name}? Ecco tutte le Categorie che Fanno al Caso Tuo!`
        meta_description = `Cerca nella categoria di ${categories.name} per trovare il giusto prodotto di cui hai bisogno, tutto in pochi secondi con un confronto prezzi e recensioni tutto in una sola pagina!`
    }

    return (
        <ContainerMain>
            <Helmet>
            <title>{meta_title}</title>
            <meta name="description" content={meta_description} />
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [${breadcrumb_meta}]
                }
            `}</script>
            </Helmet>
            {/* <Breadcrumbs breadcrumbs={page.category} /> */}
            <CategoriesContainer>
                {category_single}
            </CategoriesContainer>
            <CategoriesContainer>
                {category_group}
            </CategoriesContainer>
        </ContainerMain>
    );
}

export default Categories;
