import { Bars } from  'react-loader-spinner'

import { LoadingStyle } from '../App.style';


function Loading() {
  return (
    <LoadingStyle>
        <Bars
            height="80"
            width="80"
            ariaLabel="bars-loading"
            />
    </LoadingStyle>
  );
}

export default Loading;
