import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMediaQuery } from 'react-responsive'

import '../App.css';
import { tabletMediaQuery, mobileMediaQuery } from '../utils/responsive';
import { SearchInputStyle, SearchResultStyle, SearchInputStyleHome, SearchResultStyleHome } from '../App.style';
import { MyContext } from '../context/MyContext';
// import ProductOrder from './ProductOrder';


function Search(props) {
  const [textSearch, setTextSearch] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [searchFocused, setSearchFocused] = useState(false)
  const context = useContext(MyContext);
  const isTablet = useMediaQuery({ query: tabletMediaQuery })
  const isMobile = useMediaQuery({ query: mobileMediaQuery })

  const handleSearch = () => {
    axios({
      method: 'get',
      url: '/api/search/',
      params: {
          'query':textSearch,
      }
    })
    .then(function (response) {
        setSearchResult(response.data)
    })
    .catch(function (error) {
        console.log(error);
    })
  }

  // gestione focus campo cerca
  const handleFocus = show => {
    if (show){
      setSearchFocused(true)
    } else {
      // metto timeout quando clicco fuori altrimenti non prende i click nel menu
      setTimeout(() =>{
        setSearchFocused(false)
      }, 300)
    }
  }

  // timeout cerca
  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(textSearch), 300);
    if (textSearch==''){
      clearTimeout(timeOutId)
      setSearchResult([])
    }
    return () => {
      clearTimeout(timeOutId)
    };
  }, [textSearch]);

  useEffect(() => {
    return () => {
      setTextSearch('')
    };
  }, [context]);

  let SearchStyle = SearchInputStyle
  let ResultStyle = SearchResultStyle
  if (props.type=='home'){
    SearchStyle = SearchInputStyleHome
    ResultStyle = SearchResultStyleHome
  }

  let search_result_html = ''
  if (searchResult.length && searchFocused){
    // TODO controllo se ci sono slug doppi, da migliore nel backend
    let searchResultCleaned = searchResult.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.slug === value.slug
      ))
    )
    let search_list_html = searchResultCleaned.map((val, i)=>{
      return <Link key={i+'-'+val.slug} to={val.slug}>{val.title_url}</Link>
    })
    search_result_html = (
      <ResultStyle>
        {search_list_html}
      </ResultStyle>
    )
  }

  const search = (
    <SearchStyle>
      <FontAwesomeIcon icon="magnifying-glass"/>
      <input 
          type='text' 
          placeholder='Cerca' 
          value={textSearch}
          onFocus={() => handleFocus(true)}
          onBlur={() => handleFocus(false)}
          onChange={(e) => setTextSearch(e.target.value)}
          autoFocus={isMobile ? true : false}
          />
      {search_result_html}
    </SearchStyle>
  )


  return (
    <>
    {search}
    </>
  );
}

export default Search;
