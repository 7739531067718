import { useState } from 'react';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from 'axios';

import logo from '../logo.svg';
import '../App.css';
import { FooterStyle, NewsletterStyle, NewsletterContentStyle, FooterMenuStyle, FooterCopyrightStyle, FormMessageStyle, FormErrorStyle } from '../App.style';


function Footer() {
  const [messageForm, setMessageForm] = useState('')
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => handleSubscribeNewsletter(data);

  const handleSubscribeNewsletter = data => {
    axios({
      method: 'POST',
      url: '/api/newsletter/',
      data: data,
    })
    .then(function (response) {
        console.log(response)
        if (response.status==201){
          setMessageForm(<FormMessageStyle>Iscrizione avvenuta con successo</FormMessageStyle>)
        } else {
          throw 'Chiamata non valida';
        }
    })
    .catch(function (error) {
        setMessageForm(<FormErrorStyle>Errore durante l'invio della richiesta</FormErrorStyle>)
    })
  }


//   <NewsletterStyle>
//   <NewsletterContentStyle>
//     <p>Iscriviti e ricevi offerte speciali ed esclusive</p>
//     <form onSubmit={handleSubmit(onSubmit)}>
//       {/* <input type='email' placeholder='Email' value={textEmail} onChange={(e) => setTextEmail(e.target.value)}/> */}
//       <input type='email' placeholder='Email' {...register("email", {required: true, pattern: /^\S+@\S+$/i})}/>
//       {/* <button onClick={handleSubscribeNewsletter}>Iscriviti</button> */}
//       <input type="submit" value="Iscriviti"/>
//       {errors.email && <FormErrorStyle>Inserire un indirizzo email valido</FormErrorStyle>}
//       {messageForm}
//     </form>
//   </NewsletterContentStyle>
// </NewsletterStyle>
  return (
    <FooterStyle>
      <FooterMenuStyle>
        {/* <div>
          <h4>CATEGORIE PRINCIPALI</h4>
          <Link to='/categorie'>Categoria</Link>
          <Link to='/categorie'>Categoria</Link>
          <Link to='/categorie'>Categoria</Link>
          <Link to='/categorie'>Categoria</Link>
        </div>
        <div>
          <h4>LINK UTILI</h4>
          <Link to='/'>Contatti</Link>
          <Link to='/'>Privacy</Link>
          <Link to='/'>Cookie</Link>
          <Link to='/'>Chi siamo</Link>
        </div> */}
        <div>
          <Link to='/'>
            <img src={'/img/classificaprodottimigliori.webp'} alt="" />
          </Link>
        </div>
        <FooterCopyrightStyle>
          <p>Amazon, Amazon Prime, il logo Amazon e il logo Amazon prime sono di marchio Amazon.com, Inc. e affiliati</p>
          <p>Copyright © {new Date().getFullYear()}</p>
        </FooterCopyrightStyle>
      </FooterMenuStyle>
    </FooterStyle>
  );
}

export default Footer;
