import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ProductStyle,
        ProductVoteStyle,
        ProductInfoStyle,
        ProductImageStyle,
        ProductButtonStyle,
        ProductVoteStarStyle,
        ProductNumberStyle,
        ChoiceBannerStyle,
        BestBannerStyle,
        ProductMoreStyle,
        ProductDescriptionStyle,
        ProductMoreInfoStyle,
        ProductPriceContainerStyle,
        ProductPriceStyle,
        ProductFullPriceStyle,
        ProductDiscountStyle,
        ProductDiscountTopStyle
    } from '../App.style';


function Product(props) {
    const [showInfo, setShowInfo] = useState(false);
    let product = props.product;

    let vote = parseFloat(product.vote)
    let star_icon = ''
    const stars = [];
    // c'è uno scarto di 0.5, 5 stelle si danno se > 9.5
    for (let i=1.5;i<=9.5;i+=2){
        if (i<=vote){
            star_icon = 'star'
        }else if (i-0.5<vote){ // && i<9.5 
            star_icon = 'star-half-stroke'
        } else {
            star_icon = ["far", "star"]
        }
        stars.push(<FontAwesomeIcon key={i} icon={star_icon} />)
    }
    

    let star_name = ''
    if (product.vote > 9.5){
        star_name = 'Eccezionale'
    } else if (product.vote >= 9){
        star_name = 'Eccellente'
    } else if (product.vote > 8.5){
        star_name = 'Molto Buono'
    } else if (product.vote > 8){
        star_name = 'Buono'
    } else if (product.vote >= 0){
        star_name = 'Discreto'
    }
    
    let banner = ''
    if (product.index===1){ //product.best_choice
        banner = (<ChoiceBannerStyle>
                    <FontAwesomeIcon icon="trophy" />
                    Scelta Migliore
                </ChoiceBannerStyle>)
    } else if (product.best_value){
        banner = (<BestBannerStyle>
                    <FontAwesomeIcon icon="fire-flame-curved" />
                    Miglior Valore
                </BestBannerStyle>)
    }

    let price = ''
    let discount = ''
    if (product.full_price !== '0.00'){
        discount = Math.trunc(100 - (parseFloat(product.price)/parseFloat(product.full_price)*100));
        price = (
            <>
                <ProductPriceStyle>€{product.price}</ProductPriceStyle>
                <ProductFullPriceStyle>€{product.full_price}</ProductFullPriceStyle>
                <ProductDiscountStyle>-{discount}%</ProductDiscountStyle>
            </>
        )
    } else if (product.price !== '0.00'){
        price = <ProductPriceStyle>€{product.price}</ProductPriceStyle>
    }

    let description = ''
    if (product.description_list.length){
        let li_description = product.description_list.map(desc=>{
            return <li key={desc.id}>{desc.text}</li>
        })
        description = (
            <>
                <p>Punti salienti</p>
                <ul>{li_description}</ul>
            </>)
    }

    return (
        <ProductStyle>
            {banner}
            <ProductNumberStyle>
                {String(product.index).padStart(2, '0')}
            </ProductNumberStyle>
            <ProductImageStyle>
                <img src={product.image_file} alt=""></img>
            </ProductImageStyle>
            <ProductInfoStyle>
                <h3>{product.name}</h3>
                <p>{product.brand}</p>
                {discount ? <ProductDiscountTopStyle>{discount}% Sconto</ProductDiscountTopStyle> : ''}
            </ProductInfoStyle>
            <ProductVoteStyle>
                <span>{parseFloat(product.vote).toFixed(1)}</span>
                <ProductVoteStarStyle>
                    {stars}
                    <p>{star_name}</p>
                </ProductVoteStarStyle>
            </ProductVoteStyle>
            <ProductButtonStyle>
                <a href={product.link} target="_blank" rel="noreferrer" >Visualizza offerta</a>
                <div>
                    <span>Su Amazon</span>
                    <span onClick={() => setShowInfo(!showInfo)}>Mostra di {showInfo ? 'Meno' : 'Più'} <FontAwesomeIcon icon={showInfo ? 'chevron-up' : 'chevron-down'} /></span>
                </div>
            </ProductButtonStyle>
            <ProductMoreStyle show={showInfo}>
                <ProductDescriptionStyle>
                    {description}
                </ProductDescriptionStyle>
                <ProductMoreInfoStyle>
                    <ProductPriceContainerStyle>
                        <div>
                            {price}
                        </div>
                        <div>
                            <img src='/img/amazon_logo.svg' alt="Logo Amazon" />
                        </div>
                    </ProductPriceContainerStyle>
                    <a href={product.link} target="_blank" rel="noreferrer" >Visualizza</a>
                </ProductMoreInfoStyle>
            </ProductMoreStyle>
        </ProductStyle>
    );
}

export default Product;
