// import styled from 'styled-components'
import styled from "styled-components/macro";
import { createGlobalStyle } from 'styled-components'

import { Link } from "react-router-dom";


/*** VARIABILI ***/
const font_color1 = '#55626e' //'rgb(85, 98, 110)'
const font_color2 = '#626e79' //'rgb(98, 110, 121)'
const font_color3 = '#9fa9b3' //'rgb(159, 169, 179)'
const font_color4 = '#d1d6da' //'rgb(209, 214, 218)'

const background_color = '#f2f4f7'
const newsletter_bg = '#9fa9b3'
const bg_header_footer = '#bdbdb5' //'#31373c'
const bg_menu = '#5e5e55'
const bg_title = '#36352e'
const button_color = '#0b76e0'
const vote_background = '#f3f9ff'
const banner_choice = '#ff5c00'
const banner_choice_shadow = '#b04000'
const banner_best = '#d4eaff'
const banner_best_shadow = '#88bdf1'
const discount_bg = '#55a0ea'

    
const large = '@media (max-width: 1200px)'
const desktop = '@media (min-width: 993px)'
const tablet = '@media (max-width: 992px)'
const mobile = '@media (max-width: 576px)'

/*** GENERICO ***/
export const GlobalStyle = createGlobalStyle`
    #root{
        display:flex; 
        flex-direction:column;
        min-height: 100vh;
        background-color: ${background_color};
    }
`
export const Container = styled.div`
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
    box-sizing: border-box;
    a{
        color: black;
        text-decoration: initial;
    }
`
export const ContainerMain = styled(Container)`
    width: 100%;
    flex-grow: 1;
`
export const LoadingStyle = styled.div`
    svg{
        margin: 50px auto;
        fill: ${font_color1};
    }
`

/*** HEADER ***/
export const HeaderStyle = styled.header`
    background-color: ${bg_header_footer};
    color: white;
    a{
        color: ${font_color4};
    }
    a > img{
        height: 55px;
        width: auto;
        padding: 10px 0;
    }
    > div{
        width: 100%;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2em;
        padding: 0 10px;
        box-sizing: border-box;
    }
`
export const TitleStyle = styled.div`
    background-color: ${bg_title};
    margin-bottom: 40px;
    min-height: 60px;
    /* overflow: hidden; */
    h1{
        font-size: 3em;
        color: white;
        margin: 15px 0;
    }
    ${mobile}{
        h1{
            font-size: 2em;
        }
    }
`
export const TopCategoryStyle = styled(Container)`
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    a{
        background-color: white;
        border-radius: 10px;
        padding: 10px;
        color: ${font_color2};
        margin-right: 20px;
    }
    ::-webkit-scrollbar {
        display: none;
    }
`
export const SearchInputStyle = styled.div`
    position: relative;
    width: 20%;
    margin-left: 40%;
    input{
        width: 100%;
        font-size: 1em;
        border-radius: 8px;
        border: 0;
        padding: 6px 5px 6px 40px;
    }
    svg{
        left: 10px;
        top: 7px;
        color: ${font_color1};
        z-index: 100;
        position: absolute;
    }
    ${mobile}{
        width: 50%;
        margin-left: 0;
        svg{
            display: hidden;
        }
    }
`
export const SearchInputStyleHome = styled(SearchInputStyle)`
    display: block;
    width: 60%;
    margin: auto;
    input{
        font-size: 1.5em;
        padding: 10px 10px 10px 55px;
        box-sizing: border-box;
    }
    svg{
        font-size: 2em;
        top: 10px;
    }
    ${mobile}{
        width: 80%;
    }
`

export const SearchResultStyle = styled.div`
    position: absolute;
    top: 29px;
    z-index: 10000;
    background-color: white;
    border: 1px solid ${font_color4};
    border-top: 0;
    border-radius: 0 0 10px 10px;
    max-height: 200px;
    width: calc(100% + 43px);
    overflow: auto;
    padding-top: 5px;
    a{
        display: block;
        color: ${font_color2};
        margin: 10px 20px;
    }
`
export const SearchResultStyleHome = styled(SearchResultStyle)`
    top: 45px;
    width: 100%;
    border: 0;
    font-size: 1.5em;
    max-height: 250px;
    text-align: left;
    a{
        margin-left: 55px;
    }
`

export const LinkHeaderStyle = styled.div`
    /* width: 30%; */
    display: flex;
    justify-content: space-between;
    a{
        padding: 0 10px;
        color: ${font_color1};
        font-weight: 600;
    }
    /* ${tablet}{
        width: auto;
    } */
`
export const BreadcrumbsStyle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    color: ${font_color2};
    a{
        color: ${font_color2};
    }
    svg{
        margin: 0 10px;
    }
    ${mobile}{
        a, svg{
            display: none;
        }
    }
`
export const HeaderBottomStyle = styled.div`
    color: ${font_color4};
    display: flex;
    justify-content: space-between;
    > span{
        float: left;
        padding: 10px 0;
    }
    svg{
        margin-right: 10px;
        font-size: 1.2em;
        margin-bottom: -2px;
    }
    ${mobile}{
        font-size: 0.8em;
    }
`
export const MenuStyle = styled.div`
    width: 100%;
    min-height: 50px;
    background-color: ${bg_menu};
    > div {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    /* > div > div{
        position: relative;
    } */
    a{
        color: white;
        font-weight: 600;
        padding: 15px;
        position: relative;
    }
    ${tablet}{
        height: auto;
        > div {
            flex-direction: column;
            align-items: initial;
        }
        > div > div{
            padding-top: 20px;
        }
        a{
            font-size: 1.5em;
            padding: 0;
            margin-left: 15px;
            border-bottom: 1px solid ${background_color};
        }
        /* a:after{
            background-color: ${background_color};
            content: '';
            border: 1px solid;
            display: block;
            width: 200px;
            margin-left: 15px;
        } */
    }
`
export const MenuSubcategoryStyle = styled.div`
    /* width: 100%; */
    /* position: absolute; */
    /* top: 50px;
    left: 0; */
    background-color: ${bg_menu};
    display: flex;
    z-index: 50;
    position: absolute;
    width: 100%;
    max-width: 1200px;
    width: 100%;
    left: calc((100vw - 1200px)/2);
    a{
        display: block;
        padding: 10px 5px;
    }
    >div{
        border: 1px solid ${font_color1};
        width: 25%;
        padding: 10px;
    }
    ${large}{
        width: calc(100% - 40px);
        left: 20px;
    }
    ${tablet}{
        position: initial;
        flex-direction: column;
        padding-top: 10px;
        > div {
            width: 100%;
            border: 0;
        }
        a{
            font-size: 1rem;
            padding-left: 15px;
            margin: 0;
            border: 0;
        }
        a:after{
            content: none;
        }
    }
`
export const MenuLinkStyle = styled(Link)`
    display: block;
    color: white;
    padding: 10px 5px;
    text-transform: capitalize;
`
export const MenuLinkSelectedStyle = styled(MenuLinkStyle)`
    ${desktop}{
        ::after{
            content: " ";
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid ${font_color1};
            bottom: -10px;
            height: 0;
            left: 50%;
            margin-left: -10px;
            position: absolute;
            width: 0;
            z-index: 1010;
        }
    }
`
export const MenuSubSubcategoryStyle = styled.div`
    a{
        font-size: 0.9em;
        font-weight: 500;
        color: ${background_color};
        padding: 5px;
    }
    ${tablet}{
        a{
            font-size: 1rem;
            padding-left: 30px;
        }
    }
`
export const OrderChoosedStyle = styled.span`
    color: ${font_color4};
    position: relative;
    float: right !important;
    padding: 10px 0;
    cursor: pointer;
    svg{
        margin-left: 10px;
    }
`
export const OrderMenuStyle = styled.div`
    width: max-content;
    background-color: white;
    border-radius: 0 0 10px 10px;
    padding: 10px 20px 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1000;
    box-shadow: 3px 3px 5px #e7e7e8;
    > a{
        color: ${font_color2};
        display: block;
        padding-bottom: 20px;
    }
    ${mobile}{
        font-size: 1.4em;
    }
`
export const MenuMobileStyle = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    top: 60px;
    background-color: ${background_color};
    padding: 20px;
    box-sizing: border-box;
    z-index: 1000;
    a{
        color: black;
        text-decoration: initial;
        display: block;
        font-weight: 600;
        font-size: 1.2em;
        margin-bottom: 15px;
    }
`
export const MenuMobileSubStyle = styled.div`
    padding: 0 20px;
    a{
        color: ${font_color1} !important;
    }
`

/*** FOOTER ***/

export const FooterStyle = styled.footer`
    background-color: ${bg_header_footer};
    margin-top: auto;
    /* margin-top: 50px; */
`
export const NewsletterStyle = styled.div`
    background-color: ${newsletter_bg};
    padding: 0 20px;
`
export const NewsletterContentStyle = styled(Container)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    p{
        font-size: 2.2em;
        color: white;
        font-weight: 600;
        width: 30%;
    }
    form{
        min-height: 85px;
        padding-top: 35px;
        max-width: 60%;
    }
    > div{
        width: 50%;
        height: 55px;
        display: flex;
        justify-content: right;
    }
    button, input[type="submit"]{
        border: 0;
        background-color: ${button_color};
        border-radius: 0 10px 10px 0;
        color: white;
        cursor: pointer;
        font-size: 1.2rem;
        padding: 15px 20px 11px;
        width: 100px;
    }
    input[type="email"]{
        padding: 10px;
        border-radius: 10px 0 0 10px;
        border: 0;
        box-sizing: border-box;
        width: calc(100% - 100px);
        font-size: 1.5rem;
    }
    ${tablet}{
        p{
            font-size: 1.5em;
        }
    }
    ${mobile}{
        p{
            width: 100%;
            font-size: 1.3em;
        }
        form{
            padding: 0;
            max-width: initial;
        }
    }
`
export const FormMessageStyle = styled.span`
    color: green;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
`
export const FormErrorStyle = styled(FormMessageStyle)`
    color: red;
`
export const FooterMenuStyle = styled(Container)`
    background-color: ${bg_header_footer};
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    h4{
        color: white;
        font-size: 1.4em;
    }
    a{
        display: block;
        color:${font_color3};
        margin: 5px 0;
        font-size: 1.2em;
        width: fit-content;
    }
    /* div{
        width: 33%;
        padding: 0 10px;
        box-sizing: border-box;
    } */
    img{
        margin-top: 20px;
        height: 100px;
    }
    /* ${mobile}{
        div{
            width: 50%;
        }
    } */
`
export const FooterCopyrightStyle = styled.div`
    width: 100% !important;
    text-align: center;
    color: ${font_color1};
    margin-top: 40px;
`

/*** HOME ***/
export const ContainerHome = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 110px);
    position: relative;
    z-index: 10;
    color: white;
    >div{
        text-align: center;
        padding: 10px;
        >p{
            font-size: 1.2em;
            font-weight: 600;
            margin-bottom: 40px;
        }
    }
    h1{
        font-size: 3.5em;
        font-weight: 800;
        margin: 0;
    }
    h2{
        font-size: 2em;
        margin: 0.5em 0;
        font-weight: 600;
    }
    ${mobile}{
        min-height: auto;
        padding: 50px 0;
        h1{
            font-size: 2em;
        }
        h2{
            font-size: 1.6em;
        }
    }
`
export const ContainerHomeDescription = styled.div`
    text-align: center;
    background-color: white;
    padding-top: 20px;
    h2{
        font-size: 2em;
    }
    p{
        font-size: 1.2em;
        color: ${font_color1};
    }
    span{
        height: 2px;
        width: 75px;
        background-color: ${font_color3};
        display: block;
        margin: 50px auto;
    }
    ${mobile}{
        h2{
            font-size: 1.5em;
        }
    }
`
export const ContainerHomeCose = styled(Container)`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 30px;
    >div{
        flex: 1;
        h2{
            font-size: 2em;
        }
        p{
            font-size: 1.2em;
            color: ${font_color1};
        }
        span{
            height: 2px;
            width: 50px;
            background-color: ${font_color3};
            display: block;
        }
    }
    ${mobile}{
        >div{
            flex: auto;
            width: 100%;
            h2{
                font-size: 1.5em;
            }
        }
    }
`
export const ContainerHomeCategorie = styled(Container)`
    position: relative;
    z-index: 10;
    padding: 60px 0;
    h2{
        font-size: 2em;
    }
    p{
        font-size: 1.2em;
    }
    a{
        background-color: ${button_color};
        color: white;
        border-radius: 5px;
        border: 0;
        padding: 10px 20px;
        font-size: 1.2em;
        text-align: center;
        font-weight: 600;
        margin: 20px 0;
        display: inline-block;
    }
    ${mobile}{
        padding: 30px;
        h2{
            font-size: 1.5em;
        }
    }
`
export const HomeSquareStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
    >div{
        flex: 1;
        text-align: center;
        margin: 70px 30px 50px;
    }
    h3{
        font-size: 1.5em;
    }
    ${mobile}{
        padding-bottom: 30px;
        div{
            width: 100%;
            flex: auto;
            margin: 10px;
        }
    }
`
export const HomeBackgroundImageStyle = styled.div`
    background-image: url("/img/home_back.webp");
    background-position: center right;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    text-align: center;
    color: white;
    :before{
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: black;
        opacity: 0.75;
    }
`
export const HomeBackgroundImageStyleGradient = styled(HomeBackgroundImageStyle)`
    :before{
        background: none;
        background-image: linear-gradient(180deg, #000000 0%, ${button_color} 100%);
    }
`

/*** CATEGORYGROUP ***/ 

export const CategoriesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2em;
    padding-bottom: 50px;
`
export const CategoryGroupContainer = styled.div`
    /* width: calc(33% - 16px); */
    width: calc(100% * (1/3) - 20px);
    padding: 15px;
    border-radius: 15px;
    margin: 10px;
    background-color: white;
    box-sizing: border-box;

    ${tablet}{
        width: calc(100% * (1/2) - 20px);
    }
    ${mobile}{
        width: 100%;
    }
`
export const CategoryGroupLink = styled(Link)`
    display: block;
    margin-bottom: 15px;
`
export const CategoryGroupHead = styled.div`
    a{
        display: flex;
        align-items: center;
        color: ${font_color1};
        font-weight: 600;
    }
    span {
        display: block;
        margin: 10px auto;
        width: 80%;
        height: 1px;
        background-color: lightgray;
    }
    img{
        height: 60px;
        max-width: 60px;
        margin-right: 10px;
    }
`
export const CategoryGroupMore = styled(Link)`
    display: block;
    text-align: center;
    border-radius: 15px;
    background-color: ${background_color};
    padding: 0.5em;
    border: 0;
    font-size: 0.9em;
    color: ${font_color1} !important;
    cursor: pointer;
`

/*** PRODUCT ***/
export const ProductStyle = styled.div`
    position: relative;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    box-shadow: 1px 1px 5px #d0cfcf;
    /* ${tablet}{
        padding: 10px;
    } */
    ${mobile}{
        padding: 10px;
    }
`
export const ProductImageStyle = styled.div`
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        max-height: 110px;
        height: auto;
        max-width: 100%;
    }
    ${mobile}{
        width: 65%;
        padding: 5px 0;
    }
`
export const ProductInfoStyle = styled.div`
    width: 40%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    h3{
        font-size: 1.2em;
        color: ${font_color1};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0;
    }
    p{
        color: ${font_color2};
        text-transform: uppercase;
        margin: 0;
    }
    ${tablet}{
        width: 39%;
        margin-right: 1%;
        font-size: 2vi;
    }
    ${mobile}{
        width: 100%;
        order: 2;
        margin: 15px 0;
        font-size: 0.8rem;
        p{
            display: none;
        }
    }
`
export const ProductVoteStyle = styled.div`
    width: 10%;
    margin-top: -20px;
    background-color: ${vote_background};
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 130px;
    span{
        font-size: 2em;
        font-weight: 700;
        color: ${font_color1};
        margin-bottom: 10px;
    }
    ${tablet}{
        width: 15%;
        span{
            font-size: 4vi;
        }
    }
    ${mobile}{
        width: calc(35% + 10px);
        margin-right: -10px;
        margin-top: -10px;
        border-radius: 0 10px 0 10px;
        span{
            font-size: 2em;
        }
    }
`
export const ProductVoteStarStyle = styled.div`
    color: ${button_color};
    text-align: center;
    p{
        margin: 0;
        font-weight: 600;
    }
    svg{
        height: 0.7em;
    }
    ${tablet}{
        font-size: 2vi;
    }
    ${mobile}{
        font-size: 1em;
    }
`
export const ProductButtonStyle = styled.div`
    width: 22%;
    margin-left: 3%;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    align-items: center;
    a{
        background-color: ${button_color};
        color: white;
        border-radius: 15px;
        border: 0;
        padding: 10px;
        font-size: 1.2em;
        width: 100%;
        text-align: center;
        font-weight: 600;
    }
    div{
        width: 100%;
        display: flex;
        color: ${font_color2};
        justify-content: space-around;
        flex-wrap: wrap;
    }
    span{
        margin: 5px 5px 0;
    }
    span:last-of-type{
        cursor: pointer;
        font-weight: 600;
    }
    ${tablet}{
        width: 24%;
        margin-left: 1%;
        a{
            font-size: 2vi;
        }
        div{
            font-size: 2vi;
        }
    }
    ${mobile}{
        width: 100%;
        order: 3;
        a{
            font-size: 1.2em;
        }
        div{
            margin-top: 10px;
            font-size: 1em;
        }
    }
`
export const ProductNumberStyle = styled.div`
    background-color: white;
    position: absolute;
    left: -20px;
    top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    font-size: 1.3em;
    border-radius: 50%;
    font-weight: 700;
    color: ${font_color2};
    border: 1px solid ${font_color4};
`
export const ProductBannerStyle = styled.div`
    position: absolute;
    left: -5px;
    top: -16px;
    height: 30px;
    border-radius: 0 10px 10px 0;
    padding: 1px 10px;
    display: flex;
    align-items: center;
    font-weight: 600;
    svg{
        margin-right: 10px;
        font-size: 1.2em;
    }
    :before{
        content: '';
        width: 0px;
        height: 0px;
        border-left: 5px solid transparent;
        position: absolute;
        left: 0;
        bottom: -5px;
    }
`
export const ChoiceBannerStyle = styled(ProductBannerStyle)`
    background-color: ${banner_choice};
    color: white;
    :before{
        border-top: 5px solid ${banner_choice_shadow};
    }
`
export const BestBannerStyle = styled(ProductBannerStyle)`
    background-color: ${banner_best};
    color: ${button_color};
    :before{
        border-top: 5px solid ${banner_best_shadow};
    }
`
export const ProductMoreStyle = styled.div`
    width: 100%;
    display: ${props => props.show ? 'flex' : 'none'};
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 25px;
    ${mobile}{
        order: 4;
        flex-wrap: wrap;
    }
`
export const ProductDescriptionStyle = styled.div`
    width: 60%;
    color: ${font_color2};
    padding-left: 30px;
    /* height: 100%; */
    ul{
        margin: 0;
    }
    li{
        font-size: 0.9em;
    }
    p{
        font-size: 1.3em;
        font-weight: 700;
        margin: 0 0 0 20px;
    }
    ${mobile}{
        width: 100%;
        padding: 0;
    }
`
export const ProductMoreInfoStyle = styled.div`
    width: 20%;
    a{
        display: block;
        width: 90%;
        background-color: white;
        color: ${button_color};
        text-align: center;
        border: 1px solid ${button_color};
        border-radius: 10px;
        padding: 10px;
        font-weight: 600;
        margin-top: 20px;
        font-size: 1.1em;
    }
    ${mobile}{
        width: 100%;
        margin-top: 15px;
        a{
            width: auto;
        }
    }
`
export const ProductPriceContainerStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    div:first-of-type{
        margin-right: 10px;
    }
    img{
        margin-top: 20px;
        height: 20px;
    }
`
export const ProductPriceStyle = styled.span`
    font-size: 1.5em;
    font-weight: 700;
    display: block;
    width: 100%;
`
export const ProductFullPriceStyle = styled.span`
    font-size: 1.2em;
    color: ${font_color2};
    text-decoration-line: line-through;
    margin-right: 10px;
    margin-top: 5px;
`
export const ProductDiscountTopStyle = styled.div`
    color: white;
    background-color: ${discount_bg};
    padding: 2px 5px;
    border-radius: 5px;
    width: fit-content;
    margin-top: auto;
    ${mobile}{
        font-size: 1.2em;
        margin-top: 10px;
    }
`
export const ProductDiscountStyle = styled.span`
    color: red;
`
export const ProductSubtitleStyle = styled.h2`
    color: ${font_color1};
    font-size: 2em;
    ${mobile}{
        font-size: 1.3em;
        margin-top: 2em;
    }
`

export const ProductSquareContainerStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
`
export const ProductSquareStyle = styled(CategoryGroupContainer)`
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const ProductSquareImageStyle = styled(ProductImageStyle)`
    width: 100%;
`
export const ProductSquareInfoStyle = styled.div`
    h4{
        color: ${font_color2};
        font-size: 1.2em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 1em 0 0.5em;
    }
    >span{
        color: ${font_color3};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 10px;
    }
`
export const ProductSquarePricesStyle = styled.div`
    margin: auto 0 10px;
`
export const ProductSquareButtonStyle = styled.div`
    a{
        background-color: ${button_color};
        color: white;
        border-radius: 15px;
        border: 0;
        padding: 10px;
        font-size: 1.2em;
        display: block;
        text-align: center;
        font-weight: 600;
    }
`
export const ProductSquarePriceStyle = styled(ProductPriceStyle)`
    display: inline;
    margin-right: 10px;
`