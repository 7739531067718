import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMediaQuery } from 'react-responsive'

import logo from '../logo.svg';
import '../App.css';
import { tabletMediaQuery, mobileMediaQuery } from '../utils/responsive';
import { Container, HeaderStyle, TitleStyle, TopCategoryStyle, SearchInputStyle, LinkHeaderStyle, SearchResultStyle, HeaderBottomStyle, MenuMobileStyle, MenuMobileSubStyle } from '../App.style';
import { MyContext } from '../context/MyContext';
// import ProductOrder from './ProductOrder';
import Menu from './Menu';


function Header() {
  // TODO usare componente search 
  const [textSearch, setTextSearch] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [searchFocused, setSearchFocused] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const context = useContext(MyContext);
  const isTablet = useMediaQuery({ query: tabletMediaQuery })
  const isMobile = useMediaQuery({ query: mobileMediaQuery })
  const location = useLocation();

  const handleSearch = () => {
    axios({
      method: 'get',
      url: '/api/search/',
      params: {
          'query':textSearch,
      }
    })
    .then(function (response) {
        setSearchResult(response.data)
    })
    .catch(function (error) {
        console.log(error);
    })
  }

  // gestione focus campo cerca
  const handleFocus = show => {
    if (show){
      setSearchFocused(true)
    } else {
      // metto timeout quando clicco fuori altrimenti non prende i click nel menu
      setTimeout(() =>{
        setSearchFocused(false)
      }, 300)
    }
  }

  // timeout cerca
  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(textSearch), 300);
    if (textSearch==''){
      clearTimeout(timeOutId)
      setSearchResult([])
    }
    return () => {
      clearTimeout(timeOutId)
    };
  }, [textSearch]);

  useEffect(() => {
    setOpenMenu(false);
    return () => {
      setTextSearch('')
    };
  }, [context]);

  let search_result_html = ''
  if (searchResult.length && searchFocused){
    let searchResultCleaned = searchResult.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.slug === value.slug
      ))
    )
    let search_list_html = searchResultCleaned.map((val, i)=>{
      return <Link key={i+'-'+val.slug} to={val.slug}>{val.title_url}</Link>
    })
    search_result_html = (
      <SearchResultStyle>
        {search_list_html}
      </SearchResultStyle>
    )
  }

  let header_bottom = ''
  if (context.headerInfo.order){
    header_bottom = (
      <HeaderBottomStyle>
        <span>
          <FontAwesomeIcon icon="check-circle"/>
          Aggiornato il {new Date().toLocaleDateString()}
        </span>
        {/* <ProductOrder choosed={context.headerInfo.order} /> */}
      </HeaderBottomStyle>
    )
  }

  // responsive header
  const IconMenu = props => {
    return <FontAwesomeIcon {...props} onClick={()=>setOpenMenu(val=>!val)}/>
  }
  const LinkMenu = props => {
    return <Link {...props} onClick={()=>setOpenMenu(val=>!val)}/>
  }
  const logo_header = <Link to='/'><img src='/img/classificaprodottimigliori.webp' alt="Classifica Prodotti Migliori" /></Link>
  const search = (
    <SearchInputStyle>
      <FontAwesomeIcon icon="magnifying-glass"/>
      <input 
          type='text' 
          placeholder='Cerca' 
          value={textSearch}
          onFocus={() => handleFocus(true)}
          onBlur={() => handleFocus(false)}
          onChange={(e) => setTextSearch(e.target.value)}
          autoFocus={isMobile ? true : false}
          />
      {search_result_html}
    </SearchInputStyle>
  )
  
  // let category_top = ''
  let title_html = ''
  if (location.pathname != '/'){
    // category_top = (
    //   <TopCategoryStyle>
    //     <LinkMenu to='categorie'>Categoria</LinkMenu>
    //     <LinkMenu to='categorie'>Categoria</LinkMenu>
    //     <LinkMenu to='categorie'>Categoria</LinkMenu>
    //     <LinkMenu to='categorie'>Categoria</LinkMenu>
    //   </TopCategoryStyle>
    // )
    title_html = (
      <TitleStyle>
        <Container>
          <h1>{context.headerInfo.title}</h1>
          {header_bottom}
        </Container>
      </TitleStyle>
    )
  }

  let header = ''
  let menu_icon = ''
  let search_mobile = ''
  let menu = ''
  if (isTablet){
    menu_icon = <IconMenu icon="bars" />
    if (openMenu){
      menu_icon = <IconMenu icon="times"/>
    }
    search_mobile = logo_header;
    if (searchFocused){
      search_mobile = search;
    }
    if (openMenu){
      menu = <Menu/>
    }
  }

  if (isMobile){
    header = (
      <>
      {menu_icon}
      {search_mobile}
      <FontAwesomeIcon icon="magnifying-glass" onClick={()=>setSearchFocused(val=>!val)}/>
      </>
    )
  } else if (isTablet){
    header = (
      <>
      {menu_icon}
      {logo_header}
      {search}
      <LinkHeaderStyle>
        <Link to='categorie'>Categorie</Link>
      </LinkHeaderStyle>
      </>
    )
    
  } else {
    header = (
      <>
      {logo_header}
      {search}
      <LinkHeaderStyle>
        <Link to='categorie'>Categorie</Link>
      </LinkHeaderStyle>
      </>
    )
    menu = <Menu/>
  }

  return (
      <>
        <HeaderStyle>
          <Container>
            {header}
          </Container>
        </HeaderStyle>
        {menu}
        {title_html}
        {/* {category_top} */}
      </>
  );
}

export default Header;
