import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

import { Container, MenuStyle, MenuSubcategoryStyle, MenuSubSubcategoryStyle, MenuLinkStyle, MenuLinkSelectedStyle  } from '../App.style';
import { tabletMediaQuery, mobileMediaQuery } from '../utils/responsive';


function Menu() {
  const [categories, setCategories] = useState([]);
  const [showSub, setShowSub] = useState('');
  const isTablet = useMediaQuery({ query: tabletMediaQuery })

  useEffect(() => {
    axios({
        method: 'get',
        url: '/api/menu/',
    })
    .then(function (response) {
        // handle success
        // console.log(response.data);
        setCategories(response.data);
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
  }, []);

  const categories_html = categories.map(cat=>{
    let subcategories = ''
    if (showSub == cat.slug || isTablet){
      const sub_link = cat.categories.map(sub_cat=>{
        if (sub_cat.categories){
          const sub_sub_cat_list = sub_cat.categories.map(sub_sub_cat=>{
            let prefix = '/'
            if (sub_sub_cat.has_child){
              prefix = '/categorie/'+sub_cat.slug+'/'
            }
            return <Link key={sub_sub_cat.slug} to={prefix+sub_sub_cat.slug}>{sub_sub_cat.name}</Link>
          })
          return (
            <div key={sub_cat.slug+'_sub'}>
              <Link to={'/categorie/'+sub_cat.slug}>{sub_cat.name}</Link>
              <MenuSubSubcategoryStyle>{sub_sub_cat_list}</MenuSubSubcategoryStyle>
            </div>
          )
        }
      })
      subcategories = <MenuSubcategoryStyle key={cat.slug+'_sub'}>{sub_link}</MenuSubcategoryStyle>
    }
    let cat_link = ''
    if (showSub == cat.slug){
      cat_link = <MenuLinkSelectedStyle key={cat.slug} to={'/categorie/'+cat.slug}>{cat.name}</MenuLinkSelectedStyle>
    } else {
      cat_link = <MenuLinkStyle key={cat.slug} to={'/categorie/'+cat.slug}>{cat.name}</MenuLinkStyle>
    }
    return (
      <div onMouseOver={()=>setShowSub(cat.slug)} onMouseLeave={()=>setShowSub('')} key={cat.slug}>
        {cat_link}
        {subcategories}
      </div>
    )
  })

  return (
    <MenuStyle>
      <Container>
        {categories_html}
      </Container>
    </MenuStyle>
  );
}

export default Menu;
