import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import { Container, ContainerHome, ContainerHomeDescription, HomeSquareStyle, ContainerHomeCose, HomeBackgroundImageStyle, HomeBackgroundImageStyleGradient, ContainerHomeCategorie } from '../App.style';
import Search from '../components/Search';
import { Helmet } from 'react-helmet-async';


function Home(props) {
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     axios({
    //         method: 'get',
    //         url: 'http://159.89.2.138/api/page/',
    //         params: {
    //             'slug':page_slug
    //         }
    //     })
    //     .then(function (response) {
    //         // handle success
    //         console.log(response.data);
    //         setPage(response.data)
    //         setLoading(false);
    //     })
    //     .catch(function (error) {
    //         // handle error
    //         console.log(error);
    //     })
    // }, []);

    // if (loading){return <Loading/>}


    return (
        <>
        <Helmet>
            <title>Classifica Prodotti Migliori - Compara Miglior Prezzo Opinioni</title>
            <meta name="description" content="Classiche dei migliori prodotti divisi in categorie, per offrirti la più vasta scelta di prodotti selezionati tra migliaia di recensioni e opinioni del web." />
        </Helmet>
        <HomeBackgroundImageStyleGradient>
            <ContainerHome>
                <div>
                    <h1>I Migliori Prodotti, Al Miglior Prezzo!</h1>
                    <p>Cerca tra migliaia di categorie per trovare il miglior prodotto che fa per te!</p>
                    <Search type='home'/>
                </div>
            </ContainerHome>
        </HomeBackgroundImageStyleGradient>
        <ContainerHomeDescription>
            <Container>
                <h2>Stanco di perdere il tuo tempo in lunghe e noiose ricerche?</h2>
                <span/>
                <p>Trovare il prodotto giusto tra le migliaia di offerte online può essere stressante, faticoso e lungo.</p>
                <p>MigliorProdotto.eu è una risorsa essenziale, nata per permetterti di ottenere le migliori offerte sul web, soprattutto quando non hai il tempo di dare la caccia ai coupon, o aspettare che buoni affari (come offerte e sconti) vengano fuori.</p>
                <HomeSquareStyle>
                    <div>
                        <h3>Veloce</h3>
                        <p>Perché visitare decine di siti, quando è possibile ottenere tutti i migliori prezzi su una sola pagina?</p>
                    </div>
                    <div>
                        <h3>Facile</h3>
                        <p>Interfaccia intelligente e facile da consultare da tutti i dispositivi: pc, smartphone e tablet.</p>
                    </div>
                    <div>
                        <h3>Completo</h3>
                        <p>Ampia selezione di fornitori e categorie di prodotto, ricerca avanzata e opzioni di filtro alfabetico.</p>
                    </div>
                </HomeSquareStyle>
            </Container>
        </ContainerHomeDescription>
        <ContainerHomeCose>
            <div>
                <h2>Cos'è ClassificaProdottiMigliori.it</h2>
                <span/>
                <p>Conosciamo il web e sappiamo come individuare i prodotti meglio recensiti e più venduti.</p>
                <p>Grazie a questo sito troverai l’articolo giusto senza inutili perdite di tempo.</p>						
            </div>
            <div>

            </div>
        </ContainerHomeCose>
        <HomeBackgroundImageStyle>
            <ContainerHomeCategorie>
                <h2>La ricerca è più semplice in ordine alfabetico</h2>
                <p>Dalle action camera alle zanzariere, trovare il prodotto che stai cercando è facile con la nostra ricerca alfabetica.</p>
                <Link to='categorie'>Elenco Categorie</Link>
            </ContainerHomeCategorie>
        </HomeBackgroundImageStyle>
        </>
    );
}

export default Home;
