import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faFireFlameCurved, faMagnifyingGlass, faStar, faStarHalfStroke, faTrophy, faChevronDown, faChevronUp, faCheckCircle, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'

import './index.css';
import {GlobalStyle} from './App.style'
import App from './App';
import Home from './pages/Home'
import Categories from './pages/Categories'
import Products from './pages/Products';

import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

// icone fontawesome
library.add(faStar, farStar, faStarHalfStroke, faMagnifyingGlass, faChevronRight, faChevronDown, faChevronUp, faTrophy, faFireFlameCurved, faCheckCircle, faBars, faTimes)

// gestione url 
const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "categorie",
                element: <Categories />,
                children: [
                    {
                        path: "*",
                        element: <Categories />,
                    },
                ]
            },
            {
                path: ":page_slug",
                element: <Products />,
            }
        ]
    },
]);

// url default axois, creare un'istanza apposita per gestire robe più complesse
axios.defaults.baseURL = '/';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <GlobalStyle/>
    <RouterProvider router={router} />
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
