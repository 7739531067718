import './App.css';
import { Outlet } from "react-router-dom";

import Header from './components/Header'
import Footer from './components/Footer'
import CookieConsent from "react-cookie-consent";
import MyContextProvider from './context/MyContext';
import { HelmetProvider } from 'react-helmet-async';



function App() {
  return (
    <HelmetProvider>
      <MyContextProvider>
        <Header/>
        <Outlet/>
        <Footer/>
        {/* <CookieConsent
          buttonText="Accetta cookie"
          declineButtonText='Rifiuta cookie'
          enableDeclineButton={true}
          flipButtons
          // ButtonComponent

          // debug={true}

          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        >
          Questo sito utilizza cookie per migliorare l'esperienza di utilizzo dell'utente.
        </CookieConsent> */}
      </MyContextProvider>
    </HelmetProvider>
  );
}

export default App;
