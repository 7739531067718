import { Link, useParams } from "react-router-dom";

import '../App.css';
import { CategoryGroupContainer, CategoryGroupMore, CategoryGroupLink, CategoryGroupHead } from '../App.style';


function CategoryGroup(props) {
    const category = props.category;
    let pre_url = useParams()['*']
    pre_url = pre_url ? pre_url+'/' : ''

    let sub_categories = [];
    for (let i=0; i<5 && i<category.categories.length; i++){
        let cat = category.categories[i];
        if (cat){
            let url_cat = category.slug+'/'+cat.slug
            if (!cat.has_child){
                url_cat = '/'+cat.slug
            }
            let cat_html = <CategoryGroupLink key={cat.slug} to={url_cat}>{cat.name}</CategoryGroupLink>
            sub_categories.push(cat_html)
        }
    }
    let url_page = pre_url+category.slug
    if (category.n_categories == 0){
        url_page = '/'+category.slug // '/'+url_page
    }

    return (
        <CategoryGroupContainer>
            <CategoryGroupHead>
                <Link to={url_page}>
                    <img src={category.image} alt="" />
                    {category.name}
                </Link>
                {category.n_categories > 0 ? <span></span> : '' }
            </CategoryGroupHead>
            {sub_categories}
            {category.n_categories > 5 ? <CategoryGroupMore to={pre_url+category.slug}>Mostra di più</CategoryGroupMore> : ''}
        </CategoryGroupContainer>
    );
}

export default CategoryGroup;
