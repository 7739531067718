import React, {useState} from 'react';


export const MyContext = React.createContext();


const MyContextProvider = props => {
    const [headerInfo, setHeaderInfo] = useState({title:'', order:''});

    let values = {headerInfo:headerInfo, setHeaderInfo:setHeaderInfo}

    return (
    <MyContext.Provider value={{...values, ...props.value}} >
        {props.children}
    </MyContext.Provider>
)
}

export default MyContextProvider;
