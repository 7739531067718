import React, { useEffect, useState, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import '../App.css';
import Product from '../components/Product'
import ProductSquare from '../components/ProductSquare'
import Loading from '../components/Loading';
import Breadcrumbs from '../components/Breadcrumbs'
import { Container, ContainerMain, ProductSubtitleStyle, ProductSquareContainerStyle } from '../App.style';
import { MyContext } from '../context/MyContext';
import { Helmet } from 'react-helmet-async';


function Products() {
    const [page, setPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const context = useContext(MyContext);
    let { page_slug } = useParams();
    const start_title = 'Classifica 10 Migliori '

    useEffect(() => {
        setLoading(true);
        let order = searchParams.get('order') || 'relevant';
        axios({
            method: 'get',
            url: '/api/page/',
            params: {
                'slug':page_slug,
                'order':order
            }
        })
        .then(function (response) {
            setPage(response.data)
            const new_title = start_title + response.data.title_url //response.data.category.name
            context.setHeaderInfo(current=>({...current, 'title':new_title, 'order':order}))
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        })
    }, [page_slug, searchParams]);
        
    useEffect(() => {
        return () => {
            context.setHeaderInfo(current=>({...current, 'title':'', 'order':''}))
        };
      }, []);

    if (loading){return <Loading/>}

    let products_html = []
    let products_html_bottom = []
    let min_price = 10000
    let max_price = 0
    let first_image = ''
    // let products = page.products.sort((a,b)=>(a.top && !b.top) || a.vote<b.vote)
    if (page){
        page.products.map((product, i)=>{
            product['index'] = i+1;
            if (product.top){
                products_html.push(<Product key={product.id} product={product} />)
                let product_price = parseFloat(product.price)
                if (product_price){
                    min_price = product_price < min_price ? product_price : min_price
                    max_price = product_price > max_price ? product_price : max_price
                }
            } else {
                products_html_bottom.push(<ProductSquare key={product.id} product={product} />)
            }
        })
        first_image = page.products.length ? page.products[0].image_file : ''
    }
    min_price = min_price == 10000 ? 0 : min_price

    let meta_title = ''
    let meta_description = ''

    if (page.title_url.charAt(0) < 'N'){
        meta_title = `Classifica Migliori 10 ${page.title_url} - ClassificaProdottiMigliori.it`
        meta_description = `Trova qui i migliori 10 ${page.title_url} del web per il 2023. Confronta tra Prezzi e Recensioni il Giusto Prodotto. In Pochi Secondi Trovi Tutto ciò che stai Cercando!`
    } else {
        meta_title = `I migliori 10 ${page.title_url} del Web! - ClassificaProdottiMigliori.it`
        meta_description = `Ecco i nostri 10 migliori ${page.title_url} 2023. In pochi Minuti Ottieni ciò che stai Cercando Senza far Fatica!`

    }

    // TODO tenere un solo container
    return (
        <ContainerMain>
            <Helmet>
            <title>{meta_title}</title>
            <meta name="description" content={meta_description} />
            <script type="application/ld+json">{`
                {
                "@context": "http://schema.org",
                "@type": "Product",
                "name": "${start_title + page.title_url}",
                "brand": "Classifica Prodotti Migliori",
                "image": "${first_image}",
                "offers": {
                    "@type": "AggregateOffer",
                    "lowPrice": ${min_price},
                    "highPrice": ${max_price},
                    "priceCurrency": "EUR",
                    "offerCount": ${products_html.length}
                  }
                }
            `}</script>
            </Helmet>
            <Container>
                {products_html}
                <ProductSubtitleStyle>Offerte simili che ti potrebbero interessare</ProductSubtitleStyle>
                <ProductSquareContainerStyle>
                    {products_html_bottom}
                </ProductSquareContainerStyle>
            </Container>
        </ContainerMain>
    );
}

export default Products;
