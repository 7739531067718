import { useMediaQuery } from 'react-responsive'

// const isDesktop = () => {
//     console.log('usemediaquery')
//     let conditiorn = useMediaQuery({ query: '(min-width: 993px)' })
//     return conditiorn
// }
// export {isDesktop}
// export const isTablet = () => useMediaQuery({ query: '(max-width: 992px)' })
// export const isMobile = () => useMediaQuery({ query: '(max-width: 576px)' })

export const desktopMediaQuery = '(min-width: 993px)'
export const tabletMediaQuery = '(max-width: 992px)'
export const mobileMediaQuery = '(max-width: 576px)'