import React, { useState } from 'react';

import {ProductSquareImageStyle,
        ProductSquareButtonStyle,
        ProductSquarePriceStyle,
        ProductFullPriceStyle,
        ProductDiscountStyle,
        ProductSquareStyle,
        ProductSquareInfoStyle,
        ProductSquarePricesStyle
    } from '../App.style';


function ProductSquare(props) {
    let product = props.product;

    let price = ''
    let discount = ''
    if (product.full_price !== '0.00'){
        discount = Math.trunc(100 - (parseFloat(product.price)/parseFloat(product.full_price)*100));
        price = (
            <>
                <ProductSquarePriceStyle>€{product.price}</ProductSquarePriceStyle>
                <ProductFullPriceStyle>€{product.full_price}</ProductFullPriceStyle>
                <ProductDiscountStyle>-{discount}%</ProductDiscountStyle>
            </>
        )
    } else if (product.price !== '0.00'){
        price = <ProductSquarePriceStyle>€{product.price}</ProductSquarePriceStyle>
    }

    return (
        <ProductSquareStyle>
            <ProductSquareImageStyle>
                <img src={product.image_file} alt=""></img>
            </ProductSquareImageStyle>
            <ProductSquareInfoStyle>
                <h4>{product.name}</h4>
                {product.description ? <span>{product.description}</span> : ''}
            </ProductSquareInfoStyle>
            <ProductSquarePricesStyle>
                {price}
            </ProductSquarePricesStyle>
            <ProductSquareButtonStyle>
                <a href={product.link} target="_blank" rel="noreferrer" >Visualizza offerta</a>
            </ProductSquareButtonStyle>
        </ProductSquareStyle>
    );
}

export default ProductSquare;
